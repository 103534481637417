frappe.get_subscription = function(){

         frappe.call({
            method: "dpile.datapile.doctype.commerials.commerials.get_subscriptions",
            callback: function (r) {
                // show popup
                frappe.msgprint(r.message.replaceAll("\n","<br>"));
            }
        });

}