// on page load if url is not "/app/investors" then redirect to "/app/investors"
// var allowed_urls = ["/app/investors", "/login","/#login","/login#login"];
// $(document).ready(function() {
//     // check if window.location.pathname not in allowed_urls then redirect to "/app/investors"
//     if (!allowed_urls.includes(window.location.pathname)) {
//         window.location = "/app/investors";
//     }
// });

// // on page load or page change if url is not "/app/investors" then redirect to "/app/investors"
// $(document).on('page:change', function() {
//     if (!allowed_urls.includes(window.location.pathname)) {
//         window.location = "/app/investors";
//     }
// });
// // on url change if url is not "/app/investors" then redirect to "/app/investors"
// $(window).on('popstate', function() {
//     if (!allowed_urls.includes(window.location.pathname)) {
//         window.location = "/app/investors";
//     }
// });



!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host.replace(".i.posthog.com","-assets.i.posthog.com")+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys onSessionId".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
posthog.init('phc_gMGJl7n2W8Gk0EEf4aVswwJhFmrdEbZHxQhp1Xn1bvW',{api_host:'https://us.i.posthog.com'})


window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-B1WSFLX18C');


 

